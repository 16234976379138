import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ActionButton, HasAccessTo, SearchInput } from 'app/components';
import { CONNECTION } from 'app/common/Authorization/entities';
import { RUN } from 'app/common/Authorization/permissions';
import { TranslationKeys } from 'app/translations';
import { CAN_COMPARE, CAN_COMPARE_COMPLETED } from '../../../../../../../ConnectionDetailsPage/BatchesTab/constants';

type CompareBatchListHeaderProps = {
  isLoading?: boolean;
  onSearch: (inputValue: string) => void;
  searchQuery?: string;
  approveBatch: () => void;
  archiveBatch: () => void;
  markAsComplete: () => void;
  resendMutations: () => void;
  rejectMutations: () => void;
  batchStatus: 'draft' | 'pending-insurer-data' | 'compared' | 'to-be-checked';
  selectedCount?: number;
};

export const CompareBatchListHeader = ({
  onSearch,
  searchQuery,
  approveBatch,
  archiveBatch,
  markAsComplete,
  resendMutations,
  rejectMutations,
  batchStatus,
  selectedCount = 0,
  isLoading = false,
}: CompareBatchListHeaderProps) => {
  const canRunConnections = HasAccessTo(CONNECTION, RUN);
  const disabledApproveButton = isLoading || selectedCount === 0 || !canRunConnections;
  const [position, setPosition] = useState({
    position: 'unset',
    backgroundColor: 'none',
    top: '0%',
    margin: '0 auto',
    padding: 'unset',
    width: 'unset',
    marginLeft: 'unset',
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 340) {
        setPosition({
          margin: '0 auto',
          position: 'fixed',
          top: '64px',
          backgroundColor: '#E4F4F9',
          width: '-webkit-fill-available',
          padding: '10px 24px 10px 24px',
          marginLeft: '-24px !important',
        });
      } else {
        setPosition({
          margin: '0 auto',
          position: 'unset',
          top: 'unset',
          backgroundColor: 'unset',
          width: 'unset',
          padding: 'unset',
          marginLeft: 'unset',
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onChangeSearchInput = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearch(event.target.value);
    },
    [onSearch],
  );

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={2}
      sx={{
        position,
        zIndex: 1000,
      }}
    >
      <SearchInput
        disabled={isLoading}
        onChange={onChangeSearchInput}
        initialSearchQuery={searchQuery}
        debounceTime={500}
      />

      {CAN_COMPARE.includes(batchStatus) && (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_rejectMutationsButton}
            disabled={disabledApproveButton}
            onClick={rejectMutations}
          />
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_archiveButton}
            disabled={disabledApproveButton}
            onClick={archiveBatch}
          />
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_approveButton}
            disabled={disabledApproveButton}
            onClick={approveBatch}
          />
        </Box>
      )}

      {CAN_COMPARE_COMPLETED.includes(batchStatus) && (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_rejectMutationsButton}
            disabled={disabledApproveButton}
            onClick={rejectMutations}
          />
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_resendSelectedMutations}
            disabled={disabledApproveButton}
            onClick={resendMutations}
          />
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_markAsComplete}
            disabled={false}
            onClick={markAsComplete}
          />
        </Box>
      )}
    </Box>
  );
};
