import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Divider, Grid, LinearProgress, Typography } from '@mui/material';
import { TranslationKeys } from 'app/translations';
import type { BatchData, Connection } from 'app/types';
import type { PaginationProps } from 'app/components';
import { CompareBatchFileUpload, CompareBatchList } from './components';
import { ActionButtonStyled } from '../../../../../../components/Wizard/ Wizard.styles';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { GridItemStyled } from '../../../../../../components/ErrorPanel/ErrorPanel.styles';
import { runConnectionModule } from '../../../../Services';
import { useConnectionGetBatchQuery } from '../../../../../../hooks';

type CompareBatchPageContentProps = {
  connectionId: Connection['connectionId'] | undefined;
  batchId: string | undefined;
  batchCompareData: BatchData[];
  selected: Set<string>;
  onSelect: (selected: Set<string>) => void;
  onSearch: (inputValue: string) => void;
  startListeningForDataAfterUploadFile: (taskId: string) => void;
  approveBatch: () => void;
  archiveBatch: () => void;
  markAsCompleted: () => void;
  resendMutations: () => void;
  rejectMutations: () => void;
  paginationProps: PaginationProps;
  waitingForData?: boolean;
  isLoading?: boolean;
  searchQuery?: string;
};

export const CompareBatchPageContent = ({
  connectionId,
  batchId,
  batchCompareData,
  selected,
  onSelect,
  onSearch,
  searchQuery,
  startListeningForDataAfterUploadFile,
  approveBatch,
  archiveBatch,
  markAsCompleted,
  resendMutations,
  rejectMutations,
  paginationProps,
  waitingForData = false,
  isLoading = false,
}: CompareBatchPageContentProps) => {
  const synchronizeWithInsurer = () => {
    runConnectionModule({
      connectionId: connectionId,
      moduleType: 'insurance_brydge_fetch_insurer_data',
      parameters: {
        batchId: batchId,
      },
    });
  };

  const { data: status } = useConnectionGetBatchQuery({
    variables: { batchId, connectionId },
    options: { enabled: !!batchId && !!connectionId },
  });

  return (
    <Box marginTop={2}>
      <Grid container>
        <GridItemStyled item xs={12}>
          <ActionButtonStyled
            icon={faSync}
            messageId={TranslationKeys.global_synchronizeWithInsurer}
            onClick={synchronizeWithInsurer}
          />
        </GridItemStyled>
        <Grid item xs={12}>
          <Divider>
            <Typography textAlign={'center'} margin={2}>
              <FormattedMessage id={TranslationKeys.global_or} />
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <CompareBatchFileUpload
            isLoading={isLoading || waitingForData}
            batchId={batchId}
            connectionId={connectionId}
            startListeningForDataAfterUploadFile={startListeningForDataAfterUploadFile}
          />
        </Grid>
      </Grid>

      {waitingForData && (
        <Box marginTop={2} padding={2}>
          <Typography textAlign={'center'} marginBottom={1}>
            <FormattedMessage id={TranslationKeys.global_processing} />
          </Typography>
          <LinearProgress />
        </Box>
      )}

      {!waitingForData && (
        <CompareBatchList
          batchId={batchId}
          connectionId={connectionId}
          isLoading={isLoading}
          selected={selected}
          onSelect={onSelect}
          onSearch={onSearch}
          searchQuery={searchQuery}
          batchCompareData={batchCompareData}
          approveBatch={approveBatch}
          archiveBatch={archiveBatch}
          markAsComplete={markAsCompleted}
          resendMutations={resendMutations}
          rejectMutations={rejectMutations}
          paginationProps={paginationProps}
          batchStatus={status?.data || 'draft'}
        />
      )}
    </Box>
  );
};
