import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import type { PaginationProps } from 'app/components';
import { ListCollapsibleRow, ListContainer, ListRowNoData } from 'app/components';
import type { BatchData, BatchDataFieldType, BatchDataFieldValue } from 'app/types';
import { TranslationKeys } from 'app/translations';
import formatter from 'app/utils/formatter';
import { useLocale } from 'app/components/Intl';
import { CompareBatchListHeader, CompareBatchListInnerRow } from './components';

export const COMPARE_BATCH_LIST_DATA_TEST_ID = 'compare_batch_list_component';

type CompareBatchTableProps = {
  batchId?: string;
  connectionId?: string;
  batchCompareData: BatchData[];
  selected: Set<string>;
  onSelect: (selected: Set<string>) => void;
  onSearch: (inputValue: string) => void;
  approveBatch: () => void;
  archiveBatch: () => void;
  markAsComplete: () => void;
  resendMutations: () => void;
  rejectMutations: () => void;
  paginationProps: PaginationProps;
  isLoading?: boolean;
  searchQuery?: string;
  batchStatus: 'draft' | 'pending-insurer-data' | 'compared' | 'to-be-checked';
};

export const CompareBatchList = ({
  batchId,
  connectionId,
  selected,
  onSelect,
  onSearch,
  searchQuery,
  batchCompareData,
  approveBatch,
  archiveBatch,
  markAsComplete,
  resendMutations,
  rejectMutations,
  batchStatus,
  paginationProps,
  isLoading = false,
}: CompareBatchTableProps) => {
  const intl = useIntl();
  const { locale } = useLocale();

  const onSelectHandler = React.useCallback(
    (id: string) => () => {
      const isSelected = selected.has(id);

      if (isSelected) {
        selected.delete(id);
      } else {
        selected.add(id);
      }
      onSelect(new Set(selected));
    },
    [onSelect, selected],
  );

  const isAllPageSelected = React.useMemo(() => {
    const selectedCount = batchCompareData.reduce<number>((acc, row) => {
      if (selected.has(row.mutationExternalId)) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return batchCompareData.length === selectedCount;
  }, [batchCompareData, selected]);

  const onSelectAll = React.useCallback(() => {
    batchCompareData.forEach(({ mutationExternalId }) => {
      if (isAllPageSelected) {
        selected.delete(mutationExternalId);
      } else {
        selected.add(mutationExternalId);
      }
    });

    onSelect(new Set(selected));
  }, [isAllPageSelected, batchCompareData, onSelect, selected]);

  const onClearAllSelected = React.useCallback(() => {
    onSelect(new Set([]));
  }, [onSelect]);

  const getFormattedFieldValue = React.useCallback(
    ({ type, value }: { type: BatchDataFieldType; value: BatchDataFieldValue }) => {
      if (value === null) {
        return '-';
      }
      if (type === 'percentage' && (typeof value === 'string' || typeof value === 'number')) {
        return formatter.percentageFormat({ value, locale });
      }
      if (type === 'date') {
        return formatter.date(value);
      }
      if (type === 'boolean') {
        return formatter.yesNo(value);
      }
      if (type === 'currency' && (typeof value === 'string' || typeof value === 'number')) {
        const result = formatter.currencyFormat({ value, locale });

        return result === '' ? value : result;
      }

      return value;
    },
    [locale],
  );

  const hasData = batchCompareData?.length > 0;
  const showData = !isLoading && hasData;
  const showNoData = !isLoading && !showData;

  return (
    <Box data-testid={COMPARE_BATCH_LIST_DATA_TEST_ID} marginY={2}>
      <CompareBatchListHeader
        isLoading={isLoading}
        onSearch={onSearch}
        searchQuery={searchQuery}
        approveBatch={approveBatch}
        archiveBatch={archiveBatch}
        markAsComplete={markAsComplete}
        resendMutations={resendMutations}
        rejectMutations={rejectMutations}
        batchStatus={batchStatus}
        selectedCount={selected.size}
      />

      <ListContainer
        hasData={showData}
        containerProps={{ marginTop: 2 }}
        isLoading={isLoading}
        paginationProps={paginationProps}
        selectAllProps={{
          areAllSelected: isAllPageSelected,
          selectedCount: selected.size,
          onSelectAll,
          onClearAllSelected,
        }}
      >
        {showData &&
          (batchCompareData || []).map(data => {
            const id = data.mutationExternalId;
            const isSelected = selected.has(id);

            return (
              <ListCollapsibleRow
                key={id}
                selected={isSelected}
                onSelect={onSelectHandler(id)}
                childrenExtraLeftPadding
                rowHeader={
                  <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
                    <Typography minWidth={125} variant={'h6'}>
                      {data.mutationType}

                      {data.diffCount > 0 && (
                        <Typography marginLeft={1} variant={'caption'}>
                          {`(${data.diffCount})`}
                        </Typography>
                      )}
                    </Typography>

                    <Typography minWidth={125}>{data.employeeBSN}</Typography>
                    <Typography>{data.employeeName}</Typography>
                  </Box>
                }
              >
                <CompareBatchListInnerRow
                  key={`${id}_headers`}
                  isRowHeaders={true}
                  fieldName={intl.formatMessage({ id: TranslationKeys.global_field })}
                  mutationValue={intl.formatMessage({ id: TranslationKeys.global_mutation })}
                  insurerValue={intl.formatMessage({ id: TranslationKeys.global_insurer })}
                  externalId={data.mutationExternalId}
                />

                {(data.fields || []).map((field, idx) => {
                  const valueTextColor = field.isDifferent ? 'error' : undefined;

                  return (
                    <CompareBatchListInnerRow
                      key={`${id}_row_${idx}`}
                      fieldName={intl.formatMessage({ id: field.name })}
                      valueTextColor={valueTextColor}
                      mutationValue={getFormattedFieldValue({ type: field.type, value: field.mutationValue })}
                      insurerValue={getFormattedFieldValue({ type: field.type, value: field.insurerValue })}
                      isDifferent={field.isDifferent}
                      eventType={field.eventType}
                      externalId={data.mutationExternalId}
                      batchId={batchId}
                      connectionId={connectionId}
                    />
                  );
                })}
              </ListCollapsibleRow>
            );
          })}

        {showNoData && <ListRowNoData searchQuery={searchQuery} />}
      </ListContainer>
    </Box>
  );
};
